import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import KeySectorsGrid from "../../components/KeySectorsGrid";
import Testimonials from "../../components/testimonials/Testimonials";

const bannerImg = '../../banners/key-sectors_banner.jpg';

//RENDER
export default function SectorsPage() {
    return (
        <Layout pageTitle={'Key Sectors'}>
            <SplashBanner
                title={"Key Sectors"}
                content={"Supporting Oil & Gas, Renewable and Alternative Energy sectors with competent solutions."}
                backgroundImg={bannerImg}
            />

            <section id="keySectorsGrid">
                <div className="container">
                    <KeySectorsGrid/>
                </div>
            </section>


            <Testimonials />


        </Layout>
    )
}
